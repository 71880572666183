import React from "react";
import styles from "./index.module.scss";
import { Button, Col, Form, Image, Input, Row } from "antd";
import { Email, Phone } from "../../assets/icons";
import { useForm } from "antd/es/form/Form";

const Contact = () => {
  const [form] = useForm();

  return (
    <div>
      <div className={styles.contact}>
        <div className={styles.banner}>
          <h2 className={styles.heading}>Contact Us</h2>
          <p className={styles.text}>REQUEST A QUOTE OR GET IN TOUCH</p>
        </div>

        <div className={styles.contactForm}>
          <div className={styles.contactDetails}>
            <h4 className={styles.subHeading}>Details:</h4>

            <p className={styles.message}>Unlock precision. Elevate projects. Complimentary consultation. Exceeding expectations. Fill out the form now!</p>

            <a href="tel:+919346357575" className={styles.contactInfo}>
              <Image
                style={{ width: "14px", height: "14px" }}
                src={Phone}
                preview={false}
              />
              <p>+91-9346357575</p>
            </a>
            <a href="tel:+919381937575" className={styles.contactInfo}>
              <Image
                style={{ width: "14px", height: "14px" }}
                src={Phone}
                preview={false}
              />
              <p>+91-9381937575</p>
            </a>
            <a href="mailto:info@acecades.com" className={styles.contactInfo}>
              <Image
                style={{ width: "14px", height: "14px" }}
                src={Email}
                preview={false}
              />
              <p>info@acecades.com</p>
            </a>
          </div>

          <Form
            form={form}
            className={styles.form}
            layout="vertical"
            requiredMark={true}
          >
            <h4 className={styles.subHeading}>
              Request a quote, a call-back, or drop us a line:
            </h4>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  rules={[
                    { required: true, message: "Please input your first name" },
                  ]}
                >
                  <Input placeholder="Enter first name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  rules={[
                    { required: true, message: "Please input your last name" },
                  ]}
                >
                  <Input placeholder="Enter last name" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  rules={[
                    { required: true, message: "Please input your email" },
                  ]}
                >
                  <Input placeholder="Enter email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number",
                    },
                  ]}
                >
                  <Input placeholder="Enter phone number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Message"
                  rules={[{ required: true, message: "Please the message" }]}
                >
                  <Input.TextArea rows={4} placeholder="Enter message" />
                </Form.Item>
              </Col>
            </Row>

            <Button type="submit" className={styles.submitBtn}>
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
