import React from "react";
import { List } from "./list";
import styles from "./index.module.scss";

const Details = () => {
  const getList = (list) => {
    return list.map((item) => <div className={styles.listItem}>
      <div className={styles.titleSec}> 
        <img src={item.icon} className={styles.icon} alt="why icon" />
        <h4 className={styles.subTitle}>{item.title}</h4>
      </div>
      {/* <p className={styles.desc}>{item.message}</p> */}
    </div>);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <p className={styles.message}>
          At ACECAD Engineering, we're a team of detailers and project managers with extensive knowledge of AISC standards and building codes. We pride ourselves on delivering quality, accurate, and error-free solutions on time and at an efficient cost. Our team follows a set of rigorous quality assurance protocols for every project, ensuring that every delivery is error-free. We take the time to understand the specific requirements of each client and their shop practices, allowing us to deliver drawings that meet their unique standards and exceed their expectations. At ACECAD Engineering, we're your one-stop solution for all your structural steel needs.
        </p>
      </div>

      <div className={styles.list}>
        {getList(List)}
      </div>
    </div>
  );
};

export default Details;
