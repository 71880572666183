export const ImageCarouselDetails = [
  {
    title: "ACECAD ENGINEERING SERVICES",
    description: "Seamless Solutions for Your Steel Projects",
  },
  {
    title: "STRUCTURAL STEEL DETAILING",
    description:
      "Crafting meticulous shop drawings for a seamless steel structure. Our precision ensures flawless fabrication and erection, powered by cutting-edge tools.",
  },
  {
    title: "MISCELLANEOUS STEEL DETAILING",
    description:
      "Excellence in detailing stairs, railings, and more. Tailored drawings elevate your steel projects with precision and compliance.",
  },
  {
    title: "CONNECTION DESIGN AND STAMPING",
    description:
      "Certified designs for utmost stability. Safety-driven precision calculations empower your structures.",
  },
  {
    title: "ESTIMATION AND COSTING",
    description:
      "Comprehensive cost estimates for optimized planning. Streamlining your steel projects with efficient material take-off services.",
  },
  {
    title: "BUILDING INFORMATION MODELLING (BIM)",
    description:
      "Empowering modern steel fabrication with automated BIM solutions. Collaborate seamlessly with our industry-leading BIM expertise.",
  },
];
