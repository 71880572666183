import Contact from "../../Views/Contact";
import Home from "../../Views/Home";
import Services from "../../Views/Services";

export const RouteDetails = [
  {
    component: <Home />,
    path: "/",
    key: "home",
  },
  {
    component: <Services />,
    path: "/services",
    key: "Services",
  },
  {
    component: <Contact />,
    path: "/contact",
    key: "Contact",
  },
];
