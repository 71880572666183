import React from "react";
import { NavLink, useNavigate } from "react-router-dom"; // Import NavLink
import { Button } from "antd";
import { MenuDetails } from "./details";
import s from "./index.module.scss";

const Navigation = () => {
  const navigate = useNavigate();

  const getNavItems = () => {
    return MenuDetails.map((item) => (
      <li className={s.navItem} key={item.key}>
        <NavLink to={item.href} className={({isActive}) => isActive && s.active } >
          {item.title}
        </NavLink>
      </li>
    ));
  };

  const handleRequestBtn = () => {
    return navigate("/contact");
  };

  return (
    <nav className={s.nav}>
      <ul className={s.navList}>
        {getNavItems()}
        <Button onClick={handleRequestBtn} className={s.btnQuote}>Request Quote</Button>
      </ul>
    </nav>
  );
};

export default Navigation;
