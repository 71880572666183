export const MenuDetails = [
  {
    title: "Home",
    key: "home",
    href: "/",
  },
  {
    title: "Services",
    key: "services",
    href: "/services",
  },
  {
    title: "Contact",
    key: "contact",
    href: "/contact",
  },
];
