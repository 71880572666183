import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import { RouteDetails } from "../PrivateRoute/path";

const Container = () => {
  const getRoutes = (details) => {
    return details.map((item) => (
      <Route
        path={item.path}
        element={<PrivateRoute>{item.component}</PrivateRoute>}
      />
    ));
  };

  return (
    <Router>
      <Routes>{getRoutes(RouteDetails)}</Routes>
    </Router>
  );
};

export default Container;