import React from "react";

import ImageCarousel from "../../Components/ImageCarousel";
import ServicesDetails from "../Services/Details";
import Sectors from "../Sectors";
import Details from "./Details";

import styles from "./index.module.scss";
import { AISC, AWS, NISD, OSHA } from "../../assets/images/standards";
import Partners from "../Partner";

const Home = () => {

  const getHeader = (title) => {
    return <div className={styles.header}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.border}></div>
    </div>;
  };

  const getPartnerLogo = (List) => {
    return List.map((logo) => <img src={logo} alt="standards we follow" />)
  }

  return (
    <>
      <ImageCarousel />
      <div className={styles.content}>
        <div className={styles.services}>
          {getHeader("Services")}
          <ServicesDetails />
        </div>

        <div className={styles.why}>
          {getHeader("Why ACECAD ENGINEERING")}
          <Details />
        </div>

        <div className={styles.sectors}>
          {getHeader("Sectors We Serve")}
          <Sectors />
        </div>

        <div className={styles.partners}>
          <Partners />
        </div>

        <div className={styles.sectors}>
          {getHeader("Standards We Follow")}
          <div className={styles.list}>
            {getPartnerLogo([AISC, AWS, NISD, OSHA])}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;