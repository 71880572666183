import { Time, Quality, Team, Reliability, GrossProfit, Availability } from "../../../assets/images/Why";

export const List = [
  {
    title: "Timely Deliveries",
    icon: Time,
    message: "At ACECAD Engineering, we understand that timely deliveries are crucial for any steel detailing project. Our commitment to meeting deadlines ensures that your project stays on schedule, minimizing delays and maximizing efficiency."
  },
  {
    title: "Quality Assurance",
    icon: Quality,
    message: "Quality is the foundation of ACECAD Engineering's steel detailing services. We adhere to the highest industry standards and employ rigorous quality assurance protocols to deliver accurate and error-free drawings, ensuring the structural integrity of your project."
  },
  {
    title: "Highly Skilled Team",
    icon: Team,
    message: "Our team consists of highly skilled and experienced professionals with in-depth knowledge of AISC standards and building codes. They bring their expertise to every project, guaranteeing the highest level of precision and expertise."
  },
  {
    title: "Reliability",
    icon: Reliability,
    message: "When you partner with ACECAD Engineering, you can rely on us to be a dependable and trustworthy partner throughout your steel detailing project. We prioritize clear communication, project transparency, and consistency in delivering top-notch solutions."
  },
  {
    title: "Cost Effective",
    icon: GrossProfit,
    message: "ACECAD Engineering is committed to providing cost-effective solutions without compromising on quality. Our efficient processes, industry knowledge, and competitive pricing ensure that your project remains within budget while maintaining the highest standards."
  },
  {
    title: "24/7 Availability",
    icon: Availability,
    message: "We understand that project needs can arise at any time. That's why ACECAD Engineering offers 24/7 availability to address your inquiries and concerns promptly. We are always here to support you, ensuring a smooth and efficient project experience."
  },
];
