import React from "react";
import ServicesList from "./details";

import styles from "./index.module.scss";

const ServicesDetails = () => {
  const getServices = (list) => {
    return list.map((item) => (
      <div className={styles.card} key={item.id}>
        <img src={item.image} className={styles.image} alt="service"/>
        <h4 className={styles.title}>{item.title}</h4>
        <p className={styles.message}>{item.message}</p>
      </div>
    ));
  };

  return <div className={styles.wrapper}>{getServices(ServicesList)}</div>;
};

export default ServicesDetails;
