import React from 'react';

import styles from "./index.module.scss";
import { Image } from 'antd';
import { LogoOrange } from '../../assets';
import { MenuDetails } from '../Navigation/details';
import { Email, LinkedIn, Phone, UpWork } from '../../assets/icons';

const Footer = () => {
  const getNavItems = () => {
    return MenuDetails.map((item) => (
      <li className={styles.navItem} key={item.key}>
        <a href={item.href}>{item.title}</a>
      </li>
    ));
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <section className={styles.companyDetails}>
          <Image src={LogoOrange} preview={false} />
          <p className={styles.text}>
            Your Trusted Partner for Precision Steel Solutions. Elevating Structures with Expertise, Excellence, and Efficiency.
          </p>

          <div className={styles.socialIcons}>
              <a className={styles.icon} href="https://www.linkedin.com/company/acecad-engineering-services/" rel="noreferrer" target="_blank">
                  <Image src={LinkedIn} preview={false} />
              </a>
              {/* <a className={styles.icon} href="/">
                  <Image src={Twitter} preview={true} />
              </a> */}
              <a className={styles.icon} href="https://www.upwork.com/freelancers/deviphaneendrak" rel="noreferrer" target="_blank">
                  <Image src={UpWork} preview={false} />
              </a>
          </div>
        </section>
        
        <section className={styles.NavDetails}>
          <h4 className={styles.subHeading}>Company</h4>
          {getNavItems()}
        </section>

        <section className={styles.contactDetails}>
          <h4 className={styles.subHeading}>Contact US</h4>

          <a href="mailto:info@acecades.com" className={styles.contactInfo}>
              <Image style={{width: "14px", height: "14px"}} src={Email} preview={false} />
              <p>info@acecades.com</p>
          </a>

          <a href="tel:+919346357575" className={styles.contactInfo}>
              <Image style={{width: "14px", height: "14px"}} src={Phone} preview={false} />
              <p>+91 9346357575</p>
          </a>

          <a href="tel:+919381937575" className={styles.contactInfo}>
              <Image style={{width: "14px", height: "14px"}} src={Phone} preview={false} />
              <p>+91 9381937575</p>
          </a>
        </section>
      </div>
    </footer>
  );
};

export default Footer;