import Container from "./Route/Container";
import 'animate.css';
import "./reset.css";
import "./index.css";

function App() {
  return (
    <div className="App">
      <Container />
    </div>
  );
}

export default App;
