import React from "react";

import ServicesDetails from "./Details";
import IndustrialServices from "../Sectors";
import styles from "./index.module.scss";

const Services = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.banner}>
        <h2 className={styles.heading}>Services</h2>
        <p className={styles.text}>Discover Our Comprehensive Steel Detailing Solutions</p>
      </div>
      <ServicesDetails />

      <div className={styles.sectors}>
        <div className={styles.header}>
          <h3 className={styles.title}>Sectors We Serve</h3>
          <div className={styles.border}></div>
        </div>;
        <IndustrialServices />
      </div>
    </div>
  );
};

export default Services;
