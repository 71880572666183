import React from "react";
import SectorsList from "./details";
import { Card } from "antd";

import styles from "./index.module.scss";

const Sectors = () => {
  const getSectors = (list) => {
    return list.map((item) => (
      <Card key={item.id} className={styles.card}>
        <h4 className={styles.title}>{item.title}</h4>
        {item.list.map((sector) => <p className={styles.listItem}>
          {sector}
        </p>)}
      </Card>
    ));
  };

  return (
    <div className={styles.wrapper}>
      {getSectors(SectorsList)}
    </div>
  );
};

export default Sectors;
