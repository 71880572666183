import React from "react";
import { Image } from "antd";
import Navigation from "../../Components/Navigation";
import { Logo } from "../../assets/index";

import styles from "./index.module.scss";

const Header = () => {
  return (
    <header className={styles.wrapper}>
      <div className={styles.navBar}>
        <Image src={Logo} preview={false} />
        <Navigation />
      </div>
    </header>
  );
};

export default Header;