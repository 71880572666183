import React from "react";
import { Button, Carousel } from "antd";
import classNames from 'classnames/bind';
import styles from "./index.module.scss";
import { ImageCarouselDetails } from "./details";
import { Link } from "react-router-dom";

const ImageCarousel = () => {
  const fadeInDownDelay = "animate__animated animate__fadeInDown animate__duration-4s";

  const handleDetails = () => {
    return ImageCarouselDetails.map((details, index) => (
      <div className={classNames(styles.wrapper, styles[`image-${index + 1}`])}>
        <div className={styles.content}>
          <p className={classNames(styles.subTitle, fadeInDownDelay)}>{index === 0 ? "WELCOME TO" : "WE OFFER"}</p>
          <h1 className={classNames(styles.title, fadeInDownDelay)}>
            {details.title}
          </h1>
          <p className={classNames(styles.text, fadeInDownDelay)}>{details.description}</p>
          <div className={styles.actionBtns}>
            <Link className={classNames(styles.servicesBtn, fadeInDownDelay, "animate__delay-1s")} type="link" to="/services">SERVICES WE OFFER</Link>
            <Link className={classNames(styles.contactBtn, fadeInDownDelay, "animate__delay-1s")} type="link" to="/contact">CONTACT US</Link>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <Carousel effect="fade" autoplay dotPosition="bottom" className={styles.container}>
      {handleDetails()}
    </Carousel>
  );
};

export default ImageCarousel;
