const SectorsList = [
  {
    id: "1122",
    title: "COMMERCIAL",
    list: ["Office Buildings", "Showrooms", "Parking Structures", "Malls", "Churches"]
  },
  {
    id: "1123",
    title: "Industrial",
    list: ["Water Treatment Plants", "Equipment Support Platforms", "Maintenance Platforms", "Pipe Rack Structures", "Telecom Stations", "Loadout Structures"]
  },
  {
    id: "1144",
    title: "Infrastructures",
    list: ["Residential Apartments", "Hospital Buildings", "School Buildings", "Stadiums and Indoor Theaters"]
  },
];

export default SectorsList;
