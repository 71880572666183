import React from 'react';

import styles from "./index.module.scss";
import { Tekla } from '../../assets/images/standards';
import { useNavigate } from 'react-router-dom';

const Partners = () => {
  const navigate = useNavigate();

  const handleServices = () => {
    return navigate("/services");
  };

	return (
        <div className={styles.contentWrapper}>
            <div className={styles.content}>
                <p className={styles.subTitle}>Enhancing Precision and Efficiency</p>
                <h1 className={styles.title}>Our Trusted Software Partner</h1>
                <p className={styles.message}>At ACECAD Engineering, we believe that the key to delivering exceptional steel detailing solutions lies in our strategic partnerships. That's why we proudly collaborate with Tekla, a globally renowned leader in structural engineering and detailing software.</p>

                <button className={styles.servicesBtn} onClick={handleServices}>Our Services</button>
            </div>
            <div className={styles.demo}>
              <img src={Tekla} alt="tekla" />
            </div>
        </div>
	);
};

export default Partners;