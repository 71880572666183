import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

import styles from "./index.module.scss";

const PrivateRoute = ({ children }) => {
  return (
    <div className={styles.container}>
      <Header />
      <main className={styles.content}>{children}</main>
      <Footer />
    </div>
  );
};

export default PrivateRoute;
