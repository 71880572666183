import { SteelDetailing, MISC, ConnectionDesign, Estimation, BIM, PreReview } from "../../../assets/images/Services";

const ServicesList = [
  {
    id: "1122",
    image: SteelDetailing,
    title: "STEEL DETAILING",
    message:
      "With our team's craftsmanship and attention to detail, we create shop drawings that guarantee a seamless steel structure. Flawless fabrication and hassle-free erection are our forte, empowered by the latest industry-leading tools.",
  },
  {
    id: "2233",
    image: MISC,
    title: "MISC. STEEL DETAILING",
    message:
      "From stairs to railings and beyond, our detailers excel in providing precise drawings for all miscellaneous steel components. We elevate the quality of your steel projects with tailor-made solutions, ensuring compliance and precision.",
  },
  {
    id: "3344",
    image: ConnectionDesign,
    title: "CONNECTION DESIGN",
    message:
      "Our specialized team takes pride in delivering certified connection design solutions, ensuring the utmost stability of your steel structures. Safety is our top priority, and our precision calculations empower your projects with reliability.",
  },
  {
    id: "4455",
    image: PreReview,
    title: "PE REVIEW AND STAMPING",
    message:
      "Our expert team offers PE-certified connection design reviews and stamping services. With a strong network across 35 states, we ensure compliance and credibility for your steel projects.",
  },
  {
    id: "5566",
    image: Estimation,
    title: "ESTIMATION AND COSTING",
    message:
      "Leveraging advanced tools like PlanSwift and Bluebeam Revu, we provide comprehensive cost estimates that optimize your project planning. Our material take-off services streamline steel projects, ensuring resources are utilized efficiently.",
  },
  {
    id: "7788",
    image: BIM,
    title: "BUILDING INFORMATION MODELLING (BIM)",
    message:
      "We are adopting Modern Technologies like BIM, VR, PowerFab to meet the advanced requirements of clients in automating their fabrication process. We provide all the necessary automated outputs like Fabtrol, 3D Models, IFC files for BIM Coordination.",
  },
];

export default ServicesList;
